.item-infos{
    font-size: 12pt;
    margin-bottom: 5px;
    font-weight: bold;
    font-style: normal;
    text-decoration: none;
    text-align: left;
    color: rgb(6, 11, 51);
    outline: 0;
}

.item-block{
    padding: 7px 5px;
    flex: 0 0 auto;
}