//
// SVG Icon
//

@mixin svg-icon-size($size, $important: false) {
  svg {
    height: 2.5rem;
    width: 2.5rem;
  }
}
